import { Flex } from '@chakra-ui/react';
import Container from 'global/components/Container/Container';
import Loading from 'global/components/Loading/Loading';
import Result from 'global/utilities/result';
import { VFC } from 'react';
import TopScoresViewData from '../view-data/top-scores-view-data';
import TopCategoryLifeScores from './TopCategoryLifeScores';
import styles from './TopLifeScore.module.css';
import TopLifeScoreChart from './TopLifeScoreChart';
import TopLifeScoreComment from './TopLifeScoreComment';

type Props = {
  scoresFetchResult: Result<TopScoresViewData, Error>;
};

const TopLifeScore: VFC<Props> = ({ scoresFetchResult }) => (
    <>
      <div className={styles.sectionTitleWrapper}>
        <div className={styles.sectionTitle}>ライフスタッツ</div>
      </div>
      {scoresFetchResult.isSuccess() && (
        <div>
          <Container marginTop={16} marginBottom={10}>
            <Flex alignItems="center" justifyContent="space-between">
              <TopLifeScoreChart
                chartData={scoresFetchResult.value.totalScore}
              />
              <TopCategoryLifeScores
                categoryScores={scoresFetchResult.value.categoryScores}
              />
            </Flex>
          </Container>
          <Container marginTop={10} marginBottom={10}>
            <TopLifeScoreComment
              scoreComments={scoresFetchResult.value.scoreComments}
            />
          </Container>
        </div>
      )}
      {scoresFetchResult.isFailure() && (
        <p>{scoresFetchResult.error.message}</p>
      )}
      {scoresFetchResult.isInProgress() && <Loading />}
    </>
  );

export default TopLifeScore;
