import BottomSheet from 'features/service-description/components/BottomSheet';
import Pagination from 'features/service-description/components/Pagination';
import { VFC } from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css';
import styles from './ServiceDescriptionPage.module.css';

const contentList = [
  {
    imagePath: '/service_description/introduction_0.png',
    mainContent: '新健康サービスFitStats',
    subContent: 'FitStatsは大日本印刷が運営する、\n新たな健康サービスです！',
  },
  {
    imagePath: '/service_description/introduction_01.png',
    mainContent: '健康スコア',
    subContent:
      'FitStatsに登録したデータとFiNCのライフ\nログを組み合わせてスコアリング。',
  },
  {
    imagePath: '/service_description/introduction_02.png',
    mainContent: 'ミッションプログラム',
    subContent: '健康増進をサポートする様々なプログラムを\nお届けします。',
  },
  {
    imagePath: '/service_description/introduction_03.png',
    mainContent: 'うれしい情報、盛り沢山',
    subContent:
      'あなたの情報を安心安全に管理して、あなた\nにマッチした多彩な情報をお届けします。',
  },
  {
    imagePath: '/service_description/introduction_05.png',
    mainContent: '多彩なポイント交換先',
    subContent:
      '貯めたポイントは、さまざまな提携先のポイ\nントやクーポンに交換できます。',
  },
];

type Props = {
  index: number;
  changeIndex: (e: number) => void;
};

const ServiceDescriptionPage: VFC<Props> = ({ index, changeIndex }) => {
  SwiperCore.use([Autoplay]);

  return (
    <>
      <Swiper
        slidesPerView={1}
        loop
        modules={[Autoplay]}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          waitForTransition: false,
        }}
        className={styles.swiper}
        onSlideChange={(swiper) => {
          changeIndex(swiper.realIndex);
        }}
      >
        {contentList.map((content) => (
          <SwiperSlide key={content.mainContent} className={styles.slide}>
            <img src={`${content.imagePath}?20240515`} alt="" className={styles.image} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={styles.container}>
        <div className={styles.paginationContainer}>
          {contentList.map((c, i) => (
            <Pagination
              key={c.mainContent}
              currentIndex={index}
              listIndex={i}
            />
          ))}
        </div>
        <BottomSheet
          mainContent={contentList[index].mainContent}
          subContent={contentList[index].subContent}
        />
      </div>
    </>
  );
};

export default ServiceDescriptionPage;
